import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Quotes from "../../assets/images/quote.png"
function Testimonials() {
    var settings = {
        infinite: true,
        speed: 500,
        dots: false,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: false,
            }
          },
        ]
    };
    return (

        <div className='Testimonials'>
            <div className='testimonial-head mb-4'>…And that’s what the buzz is about!
            </div>
            <Slider {...settings}>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className='mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                        "I used to make videos for social media without ever thinking I needed a script. I’d just hit record and go with the flow. Then a friend suggested Flickvid, and I gave it a try. That’s when I realized how much structure actually matters. Now, with Flickvid, I create well-structured scripts in seconds, and my videos look way more professional, and honestly, I can’t believe I ever made videos without a proper script. This app changed my content game completely!"
                        </div>
                        <div className='testimonial-name'>
                            ~ Karan Singh 
                        </div>
                    </div>

                </div>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className=' mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                        "Making video ads for my startup used to be a nightmare, writing scripts, planning shots, and filming took forever. But now, I just give Flickvid a prompt and boom—structured scripts with a clear shot breakdown. Our latest campaign took half the time and blew up online. My boss thinks I took a professional course, but it’s just Flickvid working its magic!"
                        </div>
                        <div className='testimonial-name'>
                            ~ Meenal Joshi
                        </div>
                    </div>

                </div>
                <div className='text-center'>
                    <div className='testimonial-wraps'>
                        <div className=' mb-3'>
                            <img src={Quotes} alt="Quotes" className='img-fluid' width="80" height="80" />
                        </div>
                        <div className='testimnoial-para'>
                        "I’ve always been confident behind the camera, but scripting was my weak spot. I either depended on a writer or struggled for hours to structure my ideas. Now, I just generate a script on Flickvid, get a clear shot breakdown, and start filming. It’s a game-changer for cinematographers like me—we can focus on visuals without worrying about the writing."
                        </div>
                        <div className='testimonial-name'>
                        ~ Aditya Menon
                        </div>
                    </div>

                </div>
            </Slider>
        </div>
    )
}

export default Testimonials;